interface LoaderProps {
  mini?: boolean
}

const Loader = (props: LoaderProps) => {
  return (
    <div className="flex justify-center items-center">
      {' '}
      <div className={props.mini ? 'mini-loader' : 'full-loader'} />
    </div>
  )
}

export { Loader }
export default Loader
