import { Link } from '@faststore/ui'

type StoreProductDetailsProps = {
  Name: string
  amount: number
  quantity: number
  item_id: string
}

const StoreProductDetails = ({
  Name: name,
  amount,
  quantity,
  item_id: productId,
}: StoreProductDetailsProps) => {
  return (
    <div className="flex flex-col gap-6 justify-between pt-6 md:flex-row md:items-center">
      <div className="flex flex-col gap-2 text-[#15181B]">
        <p className="text-[0.75rem] leading-[1.125rem]">{name}</p>
        <p className="font-semibold text-[0.75rem] leading-[1.125rem]">
          <span className="text-base mr-2">
            {`R$ ${amount.toFixed(2).replace('.', ',')}`}{' '}
          </span>
          {`${quantity}un.`}
        </p>
      </div>
      <Link
        href={`https://www.decathlon.com.br/avalie-o-produto/?code=${productId}`}
        className="max-w-[155px] h-8 !py-1 !px-6 bg-[#15181B] text-white text-sm font-semibold rounded-full flex items-center hover:!no-underline"
      >
        Avaliar produto
      </Link>
    </div>
  )
}

export default StoreProductDetails
