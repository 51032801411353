import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'

type StoreAddressProps = {
  storeId: string
  storeName: string
}

const StoreAddress = ({ storeId, storeName }: StoreAddressProps) => {
  const [storeAddress, setStoreAddress] = useState<string>('')

  const getStoreAddress = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `https://decathlonstore.myvtex.com/api/dataentities/SL/search?_fields=address&_where=store_id="${
          storeId.split('-')[1]
        }"`
      )

      setStoreAddress(data[0]?.address?.split('-')[0])
    } catch (error) {
      console.error(error)
    }
  }, [storeId])

  useEffect(() => {
    getStoreAddress()
  }, [getStoreAddress])

  return (
    <>
      {storeAddress !== '' && (
        <div className="flex flex-col gap-1.5">
          <p className="text-lg text-[#15181B]">Endereço loja</p>
          <p className="capitalize">{`Decathlon ${storeName.toLowerCase()}`}</p>
          <p className="capitalize">{storeAddress.toLowerCase()}</p>
        </div>
      )}
    </>
  )
}

export default StoreAddress
