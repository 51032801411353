import { getDetailsFromTimestamp } from 'src/utils/getDetailsFromTimestamp'

import StoreProductDetails from './StoreProductDetails'
import StoreAddress from './StoreAddress'

type StoreOrderDetailsProps = {
  transaction: {
    amount: number
    business_unit_id: string
    business_unit_name: string
    business_unit_type: string
    country_id: string
    country_name: string
    currency: string
    customer_id: string
    discount_amount: number
    net_amount: number
    order_Int: any
    return_item: any
    shipping_fee: number
    tax_amount: number
    transaction_date_time: string
    transaction_id: string
    transaction_status: string
    sale_items: Array<{
      ManufacturerCode: string
      Name: string
      RefId: string
      amount: number
      category_id: string
      discount_amount: number
      item_id: string
      partner_id: string
      price_modification: Array<{
        discount_amount: number
        new_price: number
        previous_price: number
        reason_code: string
      }>
      quantity: number
      sku: string
      tax_lines: Array<{
        amount: number
        rate: number
        sub_type_code: string
        taxable_amount: number
        type_code: string
      }>
      unit_price: number
    }>
    tender: Array<{
      amount: number
      type: string
      type_code: string
    }>
  }
}

const StoreOrderDetails = ({ transaction }: StoreOrderDetailsProps) => {
  const {
    transaction_date_time: purchaseDate,
    amount: totalPrice,
    business_unit_name: storeName,
    sale_items: items,
    transaction_id: orderId,
    business_unit_id: storeId,
  } = transaction

  const { day: purchaseDay, month: purchaseMonth } =
    getDetailsFromTimestamp(purchaseDate)

  const PurchaseDateText = `Comprado em ${purchaseDay}.${purchaseMonth}`

  return (
    <div className="mb-[4.5rem]">
      <div className="flex flex-col md:border-b md:border-solid md:border-[#E1E4E7] md:pb-8">
        <p className="text-2xl font-medium text-[#15181B] md:mb-2">
          {PurchaseDateText}
        </p>
        <div className="flex flex-col gap-1 mt-8 text-[0.75rem] leading-[1.125rem] text-[#687787] md:flex-row md:gap-4 md:mt-0 md:items-center">
          <p>{`R$${totalPrice.toFixed(2).replace('.', ',')}`}</p>
          <p className="capitalize">{`Loja Decathlon ${storeName.toLowerCase()}`}</p>
        </div>
      </div>
      {items?.length > 0 && (
        <div className="flex flex-col py-16 gap-2 md:py-20">
          <p className="text-xl font-medium text-[#101010]">
            Detalhes do pedido
          </p>
          <div className="flex flex-col gap-6 divide-y divide-[#E1E4E7]">
            {items?.map((item, index) => {
              return <StoreProductDetails key={index} {...item} />
            })}
          </div>
        </div>
      )}
      <div className="flex flex-col gap-8 text-sm text-[#687787] md:flex-row md:gap-20">
        <StoreAddress storeId={storeId} storeName={storeName} />
        <div>
          <p className="text-lg text-black mb-1.5">Detalhes</p>
          <p>Número do operador: Sem no momento</p>
          <p>Caixa: Sem no momento</p>
          <p>Cupom: Sem no momento</p>
          <p className="flex flex-wrap w-fit gap-1">
            Transação: <span>{`#${orderId}`}</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default StoreOrderDetails
