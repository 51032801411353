import { useEffect, useState } from 'react'
import { useAccountContext } from 'src/components/account/context'
import OrderBreadcrumb from 'src/components/account/OrderDetail/OrderBreadcrumb'
import StoreOrderDetails from 'src/components/account/OrderDetail/StoreOrderDetails'

import LoadingSVG from './Loaders/LoadingSVG'

type StoreOrderContentProps = {
  orderId: string
}

const StoreOrderContent = ({ orderId }: StoreOrderContentProps) => {
  const [userPurchaseData, setPurchaseData] = useState<any>()

  const [order, setOrder] = useState<any>()

  const { userDecathlon } = useAccountContext()

  useEffect(() => {
    if (userDecathlon) {
      setPurchaseData(userDecathlon?.getPurchase)
    }
  }, [userDecathlon, userPurchaseData])

  useEffect(() => {
    if (!userPurchaseData) {
      return
    }

    const filteredOrder = userPurchaseData?.find(
      (orderItem: any) => orderItem?.transaction?.transaction_id === orderId
    )

    setOrder(filteredOrder)
  }, [userPurchaseData, orderId])

  if (!order) {
    return (
      <div>
        <LoadingSVG />
      </div>
    )
  }

  return (
    <section className="w-full md:max-w-[845px]">
      <OrderBreadcrumb orderId={orderId} />
      <StoreOrderDetails {...order} />
    </section>
  )
}

export default StoreOrderContent
