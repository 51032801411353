import { Link } from 'gatsby'

type OrderBreadcrumbProps = {
  orderId: string
}

const OrderBreadcrumb = ({ orderId }: OrderBreadcrumbProps) => {
  return (
    <div className="flex flex-wrap text-[0.75rem] leading-[1.125rem] text-[#687787] mb-12 md:mb-[4.5rem]">
      <Link to="/account">Minha conta</Link>
      <p className="px-1 md:px-2">/</p>
      <Link to="/account/orders">Minhas compras</Link>
      <p className="px-1 md:px-2">/</p>
      <div className="text-[#15181B]">{`Pedido #${orderId}`}</div>
    </div>
  )
}

export default OrderBreadcrumb
